<template>
  <b-card
    text-variant="center"
    class="card"
  >
    <template #header>
      <b-col class="text-left"><h6 class="mb-0">
        {{ $t("Recent tenders") }}
      </h6></b-col>
      <b-col class="text-right">
        <b-link
          to="/my-tenders"
        >{{ $t("Show all") }}</b-link>
      </b-col>
    </template>

    <b-table
      ref="refTendersListTable"
      class="position-relative"
      :items="tenders"
      responsive
      :fields="tableCols"
      primary-key="id"
      show-empty
      :empty-text="$t('No matching records found')"
      thead-class="d-none"
    >

      <!-- Column: Publication date -->
      <template #cell(publication_date)="data">
        <span class="text-nowrap ">
          {{
            new Date(data.value).toLocaleDateString('sl-si', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            })
          }}
        </span>
      </template>

      <!-- Column: contract_authority_name -->
      <template #cell(contract_authority_name)="data">
        <span
          v-b-tooltip.hover.bottom="data.item.contract_authority_name"
          class="d-inline-block text-truncate"
          style="max-width: 175px"
        >
          {{
            data.item.contract_authority_name
          }}

        </span>
      </template>

      <!-- Column: url -->
      <template #cell(url)="data">
        <b-link
          :href="data.value"
          class="font-weight-bold d-block text-nowrap"
          target="_blank"
        >
          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="ExternalLinkIcon"
            size="16"
            class="mx-1"
          />
        </b-link>
      </template>

      <!-- Column: Title -->
      <template #cell(tender_title)="data">
        <div class="text-left">
          {{ data.item.title }}
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BCol, BLink, BTable, VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BTable,
    BCard,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    tenders: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    tableCols() {
      return [
        { key: 'tender_title', label: this.$t('tender title'), sortable: true },
        { key: 'contract_authority_name', label: this.$t('contract authority name'), sortable: false },
        { key: 'publication_date', label: this.$t('date'), sortable: true },
        { key: 'url', sortable: false },
      ]
    },
  },
  methods: {},
}
</script>
