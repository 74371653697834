<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
        v-if="!userTenders || userTenders.length === 0"
        md="3"
        sm="12"
      >
        <default-welcome :user-data="userData" />
      </b-col>

      <b-col
        v-if="userTenders && userTenders.length > 0"
        md="6"
        sm="12"
      >
        <dashboard-latest-tenders :tenders="userTenders" />
      </b-col>
    </b-row>
  </section></template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import DefaultWelcome from '@/views/dashboard/default/DefaultWelcome.vue'
import DashboardLatestTenders from '@/views/dashboard/DashboardLatestTenders.vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    DashboardLatestTenders,
    BRow,
    BCol,
    DefaultWelcome,
  },
  data() {
    return {
      userData: {},
      userTenders: [],
    }
  },
  created() {
    this.userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {}

    this.fetchTenders()
  },
  methods: {
    fetchTenders() {
      store
        .dispatch('tenders/fetchDashboardTenders', { perPage: 5 })
        .then(response => {
          const { data: tenders } = response.data

          this.userTenders = tenders
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching tenders',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
