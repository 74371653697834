<template>
  <b-card
    v-if="userData"
    text-variant="center"
    class="card"
  >
    <h1 class="mb-1 mt-50 ">
      <strong>{{ $t('Hi', {name: name}) }} {{ }}</strong>,
    </h1>
    <p class="mt-50 mb-4 font-large-1">
      {{ $t('welcome back!') }}
    </p>
    <b-card-text class="m-auto w-75 text-justify">
      {{ $t(`This page is designed to give some important information about your tenders. Let's make awesome things together.`) }}
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardText,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BCardText,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    name() {
      const user = useJwt.getUserFromToken()

      return user.name
    },
  },
}
</script>
